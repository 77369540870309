<template>
  <b-container class="py-3">
    <div class="body-title font-weight-bolder">Builds & Artifacts</div>
    <section class="d-flex py-2 align-items-center justify-content-start">
      <b-avatar
        class="bg-gray-200 text-body"
        size="3em"
        rounded
        :src="app.avatar_url"
        :text="app.title | truncate(2) | upperCase"
      />
      <div v-if="app.project_type" class="ml-3">
        <icon-apple
          v-if="app.project_type === 'ios'"
          fillColor="rgba(0, 0, 0, 0.60)"
        />
        <icon-android
          v-if="app.project_type === 'android'"
          fillColor="rgba(0, 0, 0, 0.60)"
        />
      </div>
      <div class="text-xl ml-3">
        {{ app.title }}
      </div>
    </section>

    <list-build
      :items="items"
      @push-route="onPushRoute"
      @update="updateBuild"
    />

    <router-view />
    <b-overlay :show="loading" no-wrap />
  </b-container>
</template>

<script>
import ListBuild from "@/components/builds/ListBuilds";

export default {
  components: {
    ListBuild
  },
  props: {
    path: {
      type: String
    }
  },
  data() {
    return {
      loading: null,
      app: {},
      items: []
    };
  },
  mounted() {
    this.getAll();
  },
  methods: {
    async getAll() {
      try {
        const item = await this.$store.dispatch(
          "app/getBuildsForClient",
          this.path
        );

        ({ builds: this.items, ...this.app } = item);

        if (Object.keys(this.app).length === 0) {
          this.$router.push({ name: "page-error-404" });
        }
      } catch (error) {
        console.error(error);
        this.$router.push({ name: "page-error-404" });
      } finally {
        this.loading = false;
      }
    },
    onPushRoute(build_slug) {
      this.$router.push({
        name: "client.artifacts",
        params: { build_slug }
      });
    },
    async updateBuild(build_slug, data) {
      try {
        this.loading = true;
        await this.$store.dispatch("app/updateBuild", { build_slug, data });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
